import { Mixins, Component } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { availableLangs } from "@config/lang";
import LangMixin from "@/mixins/lang.mixin";

@Component
export default class LangSwitcher extends Mixins(LangMixin) {
  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly miniVariant!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly dense!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly top!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly left!: boolean;
  @Prop({ type: Number, default: -10 })
  private readonly nudgeTop!: number;

  private get minHeightCurrent() {
    // if (this.miniVariant || this.dense) return;

    return this.$vuetify.breakpoint.xlOnly ? 48 : 36;
  }

  private get langs() {
    return availableLangs;
  }

  private get activeItem() {
    return this.langs.find(({ code }) => code === this.$vuetify.lang.current);
  }

  private get showedLangs() {
    return this.langs.filter(({ code }) => code !== this.$vuetify.lang.current);
  }
}
