var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Menu',{attrs:{"top":_vm.top,"left":_vm.left,"right":!_vm.left,"bottom":!_vm.top,"nudge-top":_vm.nudgeTop,"disabled":_vm.disabled,"hide-overlay":false,"overlay-z-index":6,"offset-y":"","min-width":"195","max-width":"195","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2",attrs:{"text":"","color":"#828da0","block":!_vm.miniVariant,"icon":_vm.miniVariant,"fab":_vm.miniVariant,"small":_vm.miniVariant,"height":_vm.minHeightCurrent}},'v-btn',attrs,false),on),[(!_vm.miniVariant && !_vm.dense)?_c('span',{class:[
          'flex-grow-1',
          'text-left',
          'font-weight-medium',
          {
            'text-h6': _vm.$vuetify.breakpoint.xlOnly,
            'text-subtitle-3': !_vm.$vuetify.breakpoint.xlOnly,
          },
        ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.lang"))+" ")]):_vm._e(),_c('span',{staticClass:"d-flex align-center"},[(!_vm.miniVariant)?_c('span',{class:[
            'd-none',
            'flex-shrink-0',
            'mr-2',
            'text-capitalize',
            {
              'text-h6': !_vm.dense && _vm.$vuetify.breakpoint.xlOnly,
              'text-subtitle-3': _vm.dense || !_vm.$vuetify.breakpoint.xlOnly,
            },
          ]},[_vm._v(" "+_vm._s(_vm.activeItem.code)+" ")]):_vm._e(),_c('BaseImg',{staticClass:"d-block flex-shrink-0",attrs:{"src":require(`@/assets/img/lang/${_vm.activeItem.code}.svg`),"width":_vm.$vuetify.breakpoint.xlOnly ? 24 : 20,"contain":""}})],1),(_vm.dense)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"19"}},[_vm._v(" $arrowDropDown ")]):_vm._e()],1)]}},{key:"content",fn:function(){return [_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.showedLangs),function(lang){return _c('v-list-item',{key:lang.code,class:[
          'font-weight-medium',
          {
            'text-h6': !_vm.dense && _vm.$vuetify.breakpoint.xlOnly,
            'text-subtitle-3': _vm.dense || !_vm.$vuetify.breakpoint.xlOnly,
          },
        ],on:{"click":function($event){return _vm.changeLang(lang.code)}}},[_c('BaseImg',{staticClass:"d-block flex-shrink-0 mr-2",attrs:{"src":require(`@/assets/img/lang/${lang.code}.svg`),"width":"24"}}),_vm._v(" "+_vm._s(lang.title)+" ")],1)}),1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }